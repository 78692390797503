/* SETTINGS */
// export const CHANGE_LOCALE = 'CHANGE_LOCALE';
import axios from 'axios';
import { func } from 'prop-types';
import { setCurrentLanguage } from '../helpers/Utils';

export const settingsObject = {
  CHANGE_LOCALE: 'CHANGE_LOCALE',
};

export const authObject = {

  //AUTH
  LOGIN_USER: 'LOGIN_USER',
  LOGIN_USER_SUCCESS: 'LOGIN_USER_SUCCESS',
  LOGIN_USER_ERROR: 'LOGIN_USER_ERROR',
  REGISTER_USER: 'REGISTER_USER',
  REGISTER_USER_SUCCESS: 'REGISTER_USER_SUCCESS',
  REGISTER_USER_ERROR: 'REGISTER_USER_ERROR',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR: 'FORGOT_PASSWORD_ERROR',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',

  // MASTER MENU ACTIONS********************************
  //CUSTOMER ACTIONS********************
  ADD_CUSTOMER: 'ADD_CUSTOMER',
  VIEW_CUSTOMER: 'VIEW_CUSTOMER',
  DELETE_CUSTOMER: 'DELETE_CUSTOMER',
  GET_CUSTOMER: 'GET_CUSTOMER',
  EDIT_CUSTOMER: 'EDIT_CUSTOMER',
  VIEW_PENDING_ORDER: 'VIEW_PENDING_ORDER',
  OPENING_BALANCE_PAYMENT: 'OPENING_BALANCE_PAYMENT',
  VIEW_CUSTOMER_LIST: 'VIEW_CUSTOMER_LIST',

  //EMPLOYEE ACTIONS********************
  ADD_EMPLOYEE: 'ADD_EMPLOYEE',
  VIEW_EMPLOYEE: 'VIEW_EMPLOYEE',
  DELETE_EMPLOYEE: 'DELETE_EMPLOYEE',
  GET_EMPLOYEE: 'GET_EMPLOYEE',
  EDIT_EMPLOYEE: 'EDIT_EMPLOYEE',

  //DRIVER ACTIONS************************
  ADD_DRIVER: 'ADD_DRIVER',
  VIEW_DRIVER: 'VIEW_DRIVER',
  DELETE_DRIVER: 'DELETE_DRIVER',
  GET_DRIVER: 'GET_DRIVER',
  EDIT_DRIVER: 'EDIT_DRIVER',

  //SERVICE ACTIONS**********************
  ADD_SERVICE: 'ADD_SERVICE',
  VIEW_SERVICE: 'VIEW_SERVICE',
  DELETE_SERVICE: 'DELETE_SERVICE',
  GET_SERVICE: 'GET_SERVICE',
  EDIT_SERVICE: 'EDIT_SERVICE',

  //ACCOUNT TYPE ACTIONS**********************
  ADD_ACCOUNT: 'ADD_ACCOUNT',
  VIEW_ACCOUNT: 'VIEW_ACCOUNT',
  DELETE_ACCOUNT: 'DELETE_ACCOUNT',
  GET_ACCOUNT: 'GET_ACCOUNT',
  EDIT_ACCOUNT: 'EDIT_ACCOUNT',

  //CLOTH TYPE ACTIONS**********************
  ADD_CLOTH: 'ADD_CLOTH',
  VIEW_CLOTH: 'VIEW_CLOTH',
  DELETE_CLOTH: 'DELETE_CLOTH',
  GET_CLOTH: 'GET_ACCOUNT',
  EDIT_CLOTH: 'EDIT_CLOTH',


  //PRICE LIST ACTIONS**********************
  ADD_PRICE: 'ADD_PRICE',
  VIEW_PRICE: 'VIEW_PRICE',
  DELETE_PRICE: 'DELETE_PRICE',
  GET_PRICE: 'GET_PRICE',
  EDIT_PRICE: 'EDIT_PRICE',

  //USER TYPE ACTIONS**********************
  ADD_USERTYPE: 'ADD_USERTYPE',
  VIEW_USERTYPE: 'VIEW_USERTYPE',
  DELETE_USERTYPE: 'DELETE_USERTYPE',
  GET_USERTYPE: 'GET_USERTYPE',
  GET_USER_BY_TYPE: 'GET_USER_BY_TYPE',
  EDIT_USERTYPE: 'EDIT_USERTYPE',

  //USER ACTIONS**********************
  ADD_USER: 'ADD_USER',
  VIEW_USER: 'VIEW_USER',
  DELETE_USER: 'DELETE_USER',
  GET_USER: 'GET_USER',
  EDIT_USER: 'EDIT_USER',

  //EXPENSE CATEGORY ACTIONS**********************
  ADD_EXPCATEGORY: 'ADD_EXPCATEGORY',
  VIEW_EXPCATEGORY: 'VIEW_EXPCATEGORY',
  DELETE_EXPCATEGORY: 'DELETE_EXPCATEGORY',
  GET_EXPCATEGORY: 'GET_EXPCATEGORY',
  EDIT_EXPCATEGORY: 'EDIT_EXPCATEGORY',

  //EXPENSE DETAILS ACTIONS**********************
  ADD_EXPENSE: 'ADD_EXPENSE',
  VIEW_EXPENSE: 'VIEW_EXPENSE',
  DELETE_EXPENSE: 'DELETE_EXPENSE',
  GET_EXPENSE: 'GET_EXPENSE',
  EDIT_EXPENSE: 'EDIT_EXPENSE',
  GET_SUBCATEGORY: 'GET_SUBCATEGORY',

  //DAYBOOK ACTIONS**********************
  ADD_DAYBOOK: 'ADD_DAYBOOK',
  VIEW_DAYBOOK: 'VIEW_DAYBOOK',
  DELETE_DAYBOOK: 'DELETE_DAYBOOK',
  EDIT_DAYBOOK: 'EDIT_DAYBOOK',
  GET_DAYBOOK: 'GET_DAYBOOK',

  //COMPANY DETAILS ACTIONS**********************
  EDIT_COMPANY: 'EDIT_COMPANY',
  GET_COMPANY: 'GET_COMPANY',
  ADD_COMPANY: 'ADD_COMPANY',

  //GENERAL SETTINGS ACTIONS**********************
  EDIT_SETTINGS: 'EDIT_SETTINGS',
  GET_SETTINGS: 'GET_SETTINGS',
  ADD_SETTINGS: 'ADD_SETTINGS',

  //PRINT LABEL ACTIONS
  VIEW_ORDERS: 'VIEW_ORDERS',
  GET_LABEL: 'GET_LABEL',

  //PRIORITY LIST ACTION
  VIEW_PRIORITY: 'VIEW_PRIORITY',

  //ORDER ACTIONS**********
  ADD_ORDER: 'ADD_ORDER',
  VIEW_ORDER: 'VIEW_ORDER',
  DELETE_ORDER: 'DELETE_ORDER',
  GET_ORDER: 'GET_ORDER',
  CHANGE_STATUS: 'CHANGE_STATUS',
  EDIT_ORDER: 'EDIT_ORDER',
  PAYMENT_VIEW: 'PAYMENT_VIEW',
  LAST_UPDATED: 'LAST_UPDATED',
  ORDER_EDIT_MAIL: 'ORDER_EDIT_MAIL',
  ORDER_SMS_NOTIFICATION: 'ORDER_SMS_NOTIFICATION',

  //DASHBOARD ACTIONS**********
  VIEW_DASHBOARD: 'VIEW_DASHBOARD',
  DASHBOARD_FILTER: 'DASHBOARD_FILTER',

  //PLANT ACTIONS**********
  ADD_PLANT: 'ADD_PLANT',
  VIEW_PLANT: 'VIEW_PLANT',
  DELETE_PLANT: 'DELETE_PLANT',
  EDIT_PLANT: 'EDIT_PLANT',
  GET_PLANT: 'GET_PLANT',

    //RACK ACTIONS**********
    ADD_RACK: 'ADD_RACK',
    VIEW_RACK: 'VIEW_RACK',
    DELETE_RACK: 'DELETE_RACK',
    EDIT_RACK: 'EDIT_RACK',
    GET_RACK: 'GET_RACK',

  //POS***************************
  POS_VIEW: 'POS_VIEW',
  POS_ADDCLOTH: 'POS_ADDCLOTH',
  ASSIGN_DRIVER: 'ASSIGN_DRIVER',
  ASSIGN_EMPLOYEE: 'ASSIGN_EMPLOYEE',
  PICKUP_DRIVER: 'PICKUP_DRIVER',
  CLEANING_VIEW: 'CLEANING_VIEW',
  RECEIVED_VIEW: 'RECEIVED_VIEW',
  COMPLETED_VIEW: 'COMPLETED_VIEW',
  SORTING: 'SORTING',
  SHOW_CUSTOMER: 'SHOW_CUSTOMER',
  DUE_AMOUNT: 'DUE_AMOUNT',
  PENDIG_ORDER: 'PENDIG_ORDER',
  PAYMENT_ORDER: 'PAYMENT_ORDER',
  MULTIPLE_PAYMENT: 'MULTIPLE_PAYMENT',
  CLOSING_REPORT: 'CLOSING_REPORT',
  CLOSING_REPORT_MAIL: 'CLOSING_REPORT_MAIL',
  SERVICE_CATEGORY_COUNT: 'SERVICE_CATEGORY_COUNT',
  ADD_PAYMENT: 'ADD_PAYMENT',
  EDIT_PAYMENT: 'EDIT_PAYMENT',
  SUPPORT_MAIL: 'SUPPORT_MAIL',
  FEEDBACK_GET: 'FEEDBACK_GET',
  COMPLAINT_RESPONSE_MAIL: 'COMPLAINT_RESPONSE_MAIL',

  //************REPORT**********************************
  //customer report
  CUSTOMER_REPORT: 'CUSTOMER_REPORT',
  CUSTOMER_POS: 'CUSTOMER_POS',
  CUSTOMER_REPORT_ORDER: 'CUSTOMER_REPORT_ORDER',

  //employee report
  EMPLOYEE_REPORT: 'EMPLOYEE_REPORT',
  EMPLOYEE_REPORT_ORDER: 'EMPLOYEE_REPORT_ORDER',

  //driver report
  DRIVER_REPORT: 'DRIVER_REPORT',
  DRIVER_REPORT_ORDER: 'DRIVER_REPORT_ORDER',

  //order report
  ORDER_REPORT: 'ORDER_REPORT',
  ORDER_REPORT_EXCEL: 'ORDER_REPORT_EXCEL',

  //sales report
  SALES_REPORT: 'SALES_REPORT',

  //cash ledger report
  CASHLEDGER_REPORT: 'CASHLEDGER_REPORT',

  //debtors report
  DEBTORS_REPORT: 'DEBTORS_REPORT',
  DEBTORS_REPORT_ORDER: 'DEBTORS_REPORT_ORDER',

  //expense report
  EXPENSE_REPORT: 'EXPENSE_REPORT',

  //ready for delivery report
  READYFORDELIVERY_REPORT: 'READYFORDELIVERY_REPORT',

  //itemwise report
  ITEMWISE_REPORT: 'ITEMWISE_REPORT',

  //transaction report
  TRANSACTION_REPORT: 'TRANSACTION_REPORT',

  //edit history report
  EDIT_HISTORY_REPORT: 'EDIT_HISTORY_REPORT',

  // All Deptors Report
  ALL_DEBTORS_REPORT: "ALL_DEBTORS_REPORT",

  //service wise customer report
  SERVICEWISE_REPORT: 'SERVICEWISE_REPORT',

  //UserWise report
  USERWISE_REPORT: 'USERWISE_REPORT',

  //OUTDOOR SERVICE
  ADD_OUTDOOR_SERVICE: 'ADD_OUTDOOR_SERVICE',
  VIEW_OUTDOOR_SERVICE: 'VIEW_OUTDOOR_SERVICE',
  GET_OUTDOOR_SERVICE: 'GET_OUTDOOR_SERVICE',
  EDIT_OUTDOOR_SERVICE: 'EDIT_OUTDOOR_SERVICE',
  DELETE_OUTDOOR_SERVICE: 'DELETE_OUTDOOR_SERVICE',

  //OUTDOOR ORDER
  ADD_OUTDOOR_ORDER: 'ADD_OUTDOOR_ORDER',
  VIEW_OUTDOOR_ORDER: 'VIEW_OUTDOOR_ORDER',
  DELETE_OUTDOOR_ORDER: 'DELETE_OUTDOOR_ORDER',
  GET_OUTDOOR_ORDER: 'GET_OUTDOOR_ORDER',
  EDIT_OUTDOOR_ORDER: 'EDIT_OUTDOOR_ORDER',
  FILTER_OUTDOOR_ORDER: 'FILTER_OUTDOOR_ORDER',

  //SALES INVOICE
  SALES_INVOICE: 'SALES_INVOICE',
  CLOSSING_BALANCE: 'CLOSSING_BALANCE',
  PAGINATE: 'PAGINATE',

  // Pickup and Delivery 
  PICKUP_DRIVER: 'PICKUP_DRIVER',
  ADD_PICKUP: 'ADD_PICKUP',
  DELETE_PICKUP: 'DELETE_PICKUP',
  EDIT_PICKUP: "DELETE_PICKUP",
  APP_ORDERLIST: "APP_ORDERLIST",
  DELETE_APP_ORDER: "DELETE_APP_ORDER",
  APP_ORDER_EDIT: 'APP_ORDER_EDIT',
  APP_ORDER_STATUS: 'APP_ORDER_STATUS',
  CHANGE_APP_DELIVERY_STATUS: 'CHANGE_APP_DELIVERY_STATUS',

  // Wallet 
  ADD_WALLET: 'ADD_WALLET',
  GET_WALLET: 'GET_WALLET',
  DELETE_WALLET: 'DELETE_WALLET',
  VIEW_WALLET_CUSTOMER: 'VIEW_WALLET_CUSTOMER',
  WALLET_REPPORT: 'WALLET_REPPORT',

  // Plant 
  PLANT_WISE_REPORT: "PLANT_WISE_REPORT",
  ADD_TO_PLANT: 'ADD_TO_PLANT',
  VIEW_PLANT_LIST: 'VIEW_PLANT_LIST',
  CHANGE_PLANT_STATUS: 'CHANGE_PLANT_STATUS',
  EDIT_PLANT_LIST: 'EDIT_PLANT_LIST',
  DELETE_PLANT_LIST: 'DELETE_PLANT_LIST',

  //Payment
  CLIENT_PAYMENT: 'CLIENT_PAYMENT',
  PAYMENT_POPUP: 'PAYMENT_POPUP',
  PAY_LATER: 'PAY_LATER',
  SUPER_ADMIN_PAYMENT: 'SUPER_ADMIN_PAYMENT',

    // Rack    
    ADD_TO_RACK: 'ADD_TO_RACK',
    VIEW_RACK_LIST: 'VIEW_RACK_LIST',
    CHANGE_RACK_STATUS: 'CHANGE_RACK_STATUS',
    EDIT_RACK_LIST: 'EDIT_RACK_LIST',
    DELETE_RACK_LIST: 'DELETE_RACK_LIST',

     //Qr Code
     QR_CODE_UPDATE:'QR_CODE_UPDATE',

     //Confirm PassWord
     CLIENT_PAYMENT :'CLIENT_PAYMENT',

     //Price list import
     MODALPRICE_LIST : 'MODALPRICE_LIST',
     UPLOADPRICE_LIST : 'UPLOADPRICE_LIST',

     //database list
     DATABASE_LIST:'DATABASE_LIST',
     
     //send notification
     SEND_NOTIFICATION:'SEND_NOTIFICATION',
     NOTIFICATION_LIST:'NOTIFICATION_LIST',
     REMOVE_NOTIFICATION:'REMOVE_NOTIFICATION',

     //pricelist price edit

     PRICE_EDIT:'PRICE_EDIT',

     //User logged in and logout 
    USER_LOGGEDIN :'USER_LOGGEDIN',
    USER_LOGGEDOUT:'USER_LOGGEDOUT'
};

/* MENU */
/* export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';
*/
export const menuObject = {
  MENU_SET_CLASSNAMES: 'MENU_SET_CLASSNAMES',
  MENU_CONTAINER_ADD_CLASSNAME: 'MENU_CONTAINER_ADD_CLASSNAME',
  MENU_CLICK_MOBILE_MENU: 'MENU_CLICK_MOBILE_MENU',
  MENU_CHANGE_DEFAULT_CLASSES: 'MENU_CHANGE_DEFAULT_CLASSES',
  MENU_CHANGE_HAS_SUB_ITEM_STATUS: 'MENU_CHANGE_HAS_SUB_ITEM_STATUS',
};
// export * from './menu/actions';
// export * from './settings/actions';
// export * from './auth/actions';

// Settings actions.js
// import { settingsObject } from '../actions';

export const changeLocale = (locale) => {
  setCurrentLanguage(locale);
  return {
    type: settingsObject.CHANGE_LOCALE,
    payload: locale,
  };
};

// menu actions.js

// import { menuObject } from '../actions';

export const changeSelectedMenuHasSubItems = (payload) => {
  return {
    type: menuObject.MENU_CHANGE_HAS_SUB_ITEM_STATUS,
    payload,
  };
};

export const changeDefaultClassnames = (strCurrentClasses) => {
  return {
    type: menuObject.MENU_CHANGE_DEFAULT_CLASSES,
    payload: strCurrentClasses,
  };
};

export const addContainerClassname = (classname, strCurrentClasses) => {
  const newClasses =
    !strCurrentClasses.indexOf(classname) > -1
      ? `${strCurrentClasses} ${classname}`
      : strCurrentClasses;
  return {
    type: menuObject.MENU_CONTAINER_ADD_CLASSNAME,
    payload: newClasses,
  };
};

export const clickOnMobileMenu = (strCurrentClasses) => {
  const currentClasses = strCurrentClasses
    ? strCurrentClasses
      .split(' ')
      .filter((x) => x !== '' && x !== 'sub-show-temporary')
    : '';
  let nextClasses = '';
  if (currentClasses.includes('main-show-temporary')) {
    nextClasses = currentClasses
      .filter((x) => x !== 'main-show-temporary')
      .join(' ');
  } else {
    nextClasses = `${currentClasses.join(' ')} main-show-temporary`;
  }
  return {
    type: menuObject.MENU_CLICK_MOBILE_MENU,
    payload: { containerClassnames: nextClasses, menuClickCount: 0 },
  };
};

export const setContainerClassnames = (
  clickIndex,
  strCurrentClasses,
  selectedMenuHasSubItems
) => {
  const currentClasses = strCurrentClasses
    ? strCurrentClasses.split(' ').filter((x) => x !== '')
    : '';
  let nextClasses = '';
  if (!selectedMenuHasSubItems) {
    if (
      currentClasses.includes('menu-default') &&
      (clickIndex % 4 === 0 || clickIndex % 4 === 3)
    ) {
      clickIndex = 1;
    }
    if (currentClasses.includes('menu-sub-hidden') && clickIndex % 4 === 2) {
      clickIndex = 0;
    }
    if (
      currentClasses.includes('menu-hidden') &&
      (clickIndex % 4 === 2 || clickIndex % 4 === 3)
    ) {
      clickIndex = 0;
    }
  }

  if (clickIndex % 4 === 0) {
    if (
      currentClasses.includes('menu-default') &&
      currentClasses.includes('menu-sub-hidden')
    ) {
      nextClasses = 'menu-default menu-sub-hidden';
    } else if (currentClasses.includes('menu-default')) {
      nextClasses = 'menu-default';
    } else if (currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-sub-hidden';
    } else if (currentClasses.includes('menu-hidden')) {
      nextClasses = 'menu-hidden';
    }
    clickIndex = 0;
  } else if (clickIndex % 4 === 1) {
    if (
      currentClasses.includes('menu-default') &&
      currentClasses.includes('menu-sub-hidden')
    ) {
      nextClasses = 'menu-default menu-sub-hidden main-hidden sub-hidden';
    } else if (currentClasses.includes('menu-default')) {
      nextClasses = 'menu-default sub-hidden';
    } else if (currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-sub-hidden main-hidden sub-hidden';
    } else if (currentClasses.includes('menu-hidden')) {
      nextClasses = 'menu-hidden main-show-temporary';
    }
  } else if (clickIndex % 4 === 2) {
    if (
      currentClasses.includes('menu-default') &&
      currentClasses.includes('menu-sub-hidden')
    ) {
      nextClasses = 'menu-default menu-sub-hidden sub-hidden';
    } else if (currentClasses.includes('menu-default')) {
      nextClasses = 'menu-default main-hidden sub-hidden';
    } else if (currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-sub-hidden sub-hidden';
    } else if (currentClasses.includes('menu-hidden')) {
      nextClasses = 'menu-hidden main-show-temporary sub-show-temporary';
    }
  } else if (clickIndex % 4 === 3) {
    if (
      currentClasses.includes('menu-default') &&
      currentClasses.includes('menu-sub-hidden')
    ) {
      nextClasses = 'menu-default menu-sub-hidden sub-show-temporary';
    } else if (currentClasses.includes('menu-default')) {
      nextClasses = 'menu-default sub-hidden';
    } else if (currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-sub-hidden sub-show-temporary';
    } else if (currentClasses.includes('menu-hidden')) {
      nextClasses = 'menu-hidden main-show-temporary';
    }
  }
  if (currentClasses.includes('menu-mobile')) {
    nextClasses += ' menu-mobile';
  }
  return {
    type: menuObject.MENU_SET_CLASSNAMES,
    payload: { containerClassnames: nextClasses, menuClickCount: clickIndex },
  };
};

//***********SYSWASH*****************************************

//AUTH
export function changePassword(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/changepass?code=${code}`;
  return {
    type: authObject.CHANGE_PASSWORD,
    payload: { request: { url, data, method: 'put' } },
  };

}

//user register api*****************************
export function registernewUser(data) {
  const url = '/api/syswash/user';
  return {
    type: authObject.REGISTER_USER,
    payload: { request: { url, data, method: 'POST' } },
  };

}

//user login api*********************************
export function loginnewUser(data) {
  const url = '/api/token/';
  return {
    type: authObject.LOGIN_USER,
    payload: { request: { url, data, method: 'POST' } },
  };
}

//***********CUSTOMER****************
//add customer api***************************************
export function addNewCustomer(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/customerdetails?code=${code}`;
  return {
    type: authObject.ADD_CUSTOMER,
    payload: { request: { url, data, method: 'POST' } },
  };
}

//view all customer api*************************************
export function viewAllCustomer(area = null, acType = null,next=null, deliveryType = null,search) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/customerdetails?area=${area}&acType=${acType}&page=${next}&deliveryType=${deliveryType}&code=${code}&search=${search}`;
  return {
    type: authObject.VIEW_CUSTOMER,
    payload: { request: { url, method: 'GET' } },
  };
}

//get customer by id api************************************
export function getCustomer(id, area = null, acType = null, deliveryType = null,search) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/customerdetails/${id}?area=${area}&acType=${acType}&deliveryType=${deliveryType}&code=${code}&search=${search}`;
  return {
    type: authObject.GET_CUSTOMER,
    payload: { request: { url, method: 'GET' } },
  };
}

//delete customer by id api************************************
export function deleteCustomer(id, userName) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/customerdetails/${id}?code=${code}`;
  return {
    type: authObject.DELETE_CUSTOMER,
    payload: {
      request: {
        url,
        method: 'DELETE',
        data: { userName },  
      }
    },
  };
}

//edit customer api**************************************
export function addEditedCustomer(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/customerdetails/${id}?code=${code}`;
  return {
    type: authObject.EDIT_CUSTOMER,
    payload: { request: { url, data, method: 'PUT' } },
  };
}

//edit customer api**************************************
export function viewPendingOrder(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/customerorder/${id}?code=${code}`;
  return {
    type: authObject.VIEW_PENDING_ORDER,
    payload: { request: { url, method: 'GET' } },
  };
}

//opening balance api**************************************
export function openingBalancePayment(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/openingPayment?code=${code}`;
  return {
    type: authObject.OPENING_BALANCE_PAYMENT,
    payload: { request: { url, data, method: 'POST' } },
  };
}

//***********EMPLOYEE****************
//add employee api***************************************
export function addNewEmployee(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/employeedetails?code=${code}`;
  return {
    type: authObject.ADD_EMPLOYEE,
    payload: { request: { url, data, method: 'POST' } },
  };
}

//view all customer api*************************************
export function viewAllEmployee() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/employeedetails?code=${code}`;
  return {
    type: authObject.VIEW_EMPLOYEE,
    payload: { request: { url, method: 'GET' } },
  };
}

//delete employee by id api************************************
export function deleteEmployee(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/employeedetails/${id}?code=${code}`;
  return {
    type: authObject.DELETE_EMPLOYEE,
    payload: { request: { url, method: 'DELETE' } },
  };
}

//get employee by id api************************************
export function getEmployee(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/employeedetails/${id}?code=${code}`;
  return {
    type: authObject.GET_EMPLOYEE,
    payload: { request: { url, method: 'GET' } },
  };
}

//edit employee api**************************************
export function addEditedEmployee(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/employeedetails/${id}?code=${code}`;
  return {
    type: authObject.EDIT_EMPLOYEE,
    payload: { request: { url, data, method: 'PUT' } },
  };
}

//***********SERVICE****************
//add service api***************************************
export function addNewService(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/servicedetails?code=${code}`;
  return {
    type: authObject.ADD_SERVICE,
    payload: { request: { url, data, method: 'POST' } },
  };
}

//view all service api*************************************
export function viewAllService() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/servicedetails?code=${code}`;
  return {
    type: authObject.VIEW_SERVICE,
    payload: { request: { url, method: 'GET' } },
  };
}

//delete service by id api************************************
export function deleteService(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/servicedetails/${id}?code=${code}`;
  return {
    type: authObject.DELETE_SERVICE,
    payload: { request: { url, method: 'DELETE' } },
  };
}

//get service by id api************************************
export function getService(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/servicedetails/${id}?code=${code}`;
  return {
    type: authObject.GET_SERVICE,
    payload: { request: { url, method: 'GET' } },
  };
}

//edit service api**************************************
export function addEditedService(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/servicedetails/${id}?code=${code}`;
  return {
    type: authObject.EDIT_SERVICE,
    payload: { request: { url, data, method: 'PUT' } },
  };
}

//***********DRIVER****************
//add driver api***************************************
export function addNewDriver(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/driverdetails?code=${code}`;
  return {
    type: authObject.ADD_DRIVER,
    payload: { request: { url, data, method: 'POST' } },
  };
}

//view all drivers
export function viewAllDriver() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/driverdetails?code=${code}`;
  return {
    type: authObject.VIEW_DRIVER,
    payload: { request: { url, method: 'GET' } },
  };
}

//delete driver
export function deleteDriver(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/driverdetails/${id}?code=${code}`;
  return {
    type: authObject.DELETE_DRIVER,
    payload: { request: { url, method: 'DELETE' } },
  };
}

//get driver by id api************************************
export function getDriver(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/driverdetails/${id}?code=${code}`;
  return {
    type: authObject.GET_DRIVER,
    payload: { request: { url, method: 'GET' } },
  };
}

//edit service api**************************************
export function addEditedDriver(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/driverdetails/${id}?code=${code}`;
  return {
    type: authObject.EDIT_DRIVER,
    payload: { request: { url, data, method: 'PUT' } },
  };
}

//***********ACCOUNT TYPE****************
//add account type api***************************************
export function addAccountType(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/accountdetails?code=${code}`;
  return {
    type: authObject.ADD_ACCOUNT,
    payload: { request: { url, data, method: 'POST' } },
  };
}

//view all account types
export function viewAccountType() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/accountdetails?code=${code}`;
  return {
    type: authObject.VIEW_ACCOUNT,
    payload: { request: { url, method: 'GET' } },
  };
}

//delete account types
export function deleteAccountType(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/accountdetails/${id}?code=${code}`;
  return {
    type: authObject.DELETE_ACCOUNT,
    payload: { request: { url, method: 'DELETE' } },
  };
}

//get account type by id
export function getAccountType(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/accountdetails/${id}?code=${code}`;
  return {
    type: authObject.GET_ACCOUNT,
    payload: { request: { url, method: 'GET' } },
  };
}

//edit account type api**************************************
export function addEditedAccount(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/accountdetails/${id}?code=${code}`;
  return {
    type: authObject.EDIT_ACCOUNT,
    payload: { request: { url, data, method: 'PUT' } },
  };
}

//***********PLANT****************
//add plant api***************************************
export function addPlant(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/plant?code=${code}`;
  return {
    type: authObject.ADD_PLANT,
    payload: { request: { url, data, method: 'POST' } },
  };
}

//view all plants
export function viewPlant() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/plant?code=${code}`;
  return {
    type: authObject.VIEW_PLANT,
    payload: { request: { url, method: 'GET' } },
  };
}

//delete plants
export function deletePlant(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/plant/${id}?code=${code}`;
  return {
    type: authObject.DELETE_PLANT,
    payload: { request: { url, method: 'DELETE' } },
  };
}

//get plant by id
export function getPlant(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/plant/${id}?code=${code}`;
  return {
    type: authObject.GET_PLANT,
    payload: { request: { url, method: 'GET' } },
  };
}

//edit plant api**************************************
export function addEditedPlant(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/plant/${id}?code=${code}`;
  return {
    type: authObject.EDIT_PLANT,
    payload: { request: { url, data, method: 'PUT' } },
  };
}

//***********CLOTH TYPE****************
//add cloth type api***************************************
export function addClothType(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/clothdetails?code=${code}`;
  return {
    type: authObject.ADD_CLOTH,
    payload: { request: { url, data, method: 'POST' } },
  };
}

//view cloth type api***************************************
export function viewClothType() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/clothdetails?code=${code}`;
  return {
    type: authObject.VIEW_CLOTH,
    payload: { request: { url, method: 'GET' } },
  };
}

//get cloth type by td api***************************************
export function getClothType(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/clothdetails/${id}?code=${code}`;
  return {
    type: authObject.GET_CLOTH,
    payload: { request: { url, method: 'GET' } },
  };
}

//delete account types
export function deleteClothType(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/clothdetails/${id}?code=${code}`;
  return {
    type: authObject.DELETE_CLOTH,
    payload: { request: { url, method: 'DELETE' } },
  };
}

//edit cloth api**************************************
export function addEditedCloth(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/clothdetails/${id}?code=${code}`;
  return {
    type: authObject.EDIT_CLOTH,
    payload: { request: { url, data, method: 'PUT' } },
  };
}

//***********PRICE LIST****************
//add price api***************************************
export function addPriceList(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/pricedetails?code=${code}`;
  return {
    type: authObject.ADD_PRICE,
    payload: { request: { url, data, method: 'POST' } },
  };
}

//view price list
export function viewPriceList() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/pricedetails?code=${code}`;
  return {
    type: authObject.VIEW_PRICE,
    payload: { request: { url, method: 'GET' } },
  };
}

//get price by id
export function getPriceList(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/pricedetails/${id}?code=${code}`;
  return {
    type: authObject.GET_PRICE,
    payload: { request: { url, method: 'GET' } },
  };
}

//edit price api**************************************
export function addEditedPrice(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/pricedetails/${id}?code=${code}`;
  return {
    type: authObject.EDIT_PRICE,
    payload: { request: { url, data, method: 'PUT' } },
  };
}

//delete price
export function deletePriceList(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/pricedetails/${id}?code=${code}`;
  return {
    type: authObject.DELETE_PRICE,
    payload: { request: { url, method: 'DELETE' } },
  };
}

//***********USER TYPE****************
//add user type api***************************************
export function addUserTypes(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/usertypes?code=${code}`;
  return {
    type: authObject.ADD_USERTYPE,
    payload: { request: { url, data, method: 'POST' } },
  };
}

//view user type api***************************************
export function viewUserTypes(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/usertypes?code=${code}`;
  return {
    type: authObject.VIEW_USERTYPE,
    payload: { request: { url, data, method: 'GET' } },
  };
}

//delete user type
export function deleteUserType(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/usertypes/${id}?code=${code}`;
  return {
    type: authObject.DELETE_USERTYPE,
    payload: { request: { url, method: 'DELETE' } },
  };
}

//get user type by id
export function getUserType(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/usertypes/${id}?code=${code}`;
  return {
    type: authObject.GET_USERTYPE,
    payload: { request: { url, method: 'GET' } },
  };
}

export function getUserByType(type) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/usertypes/${type}?code=${code}`;
  return {
    type: authObject.GET_USER_BY_TYPE,
    payload: { request: { url, method: 'GET' } },
  };
}



//edit userType api**************************************
export function addEditedUserType(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/usertypes/${id}?code=${code}`;
  return {
    type: authObject.EDIT_USERTYPE,
    payload: { request: { url, data, method: 'PUT' } },
  };
}

//***********USER****************
//add user api***************************************
export function addUser(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/user?code=${code}`;
  return {
    type: authObject.ADD_USER,
    payload: { request: { url, data, method: 'POST' } },
  };
}

//view user api***************************************
export function viewUser(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/user?code=${code}`;
  return {
    type: authObject.VIEW_USER,
    payload: { request: { url, data, method: 'GET' } },
  };
}

//get price by id
export function getUser(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/user/${id}?code=${code}`;
  return {
    type: authObject.GET_USER,
    payload: { request: { url, method: 'GET' } },
  };
}

//edit price api**************************************
export function addEditedUser(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/user/${id}?code=${code}`;
  return {
    type: authObject.EDIT_USER,
    payload: { request: { url, data, method: 'PUT' } },
  };
}

//delete user 
export function deleteUser(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/user/${id}?code=${code}`;
  return {
    type: authObject.DELETE_USER,
    payload: { request: { url, method: 'DELETE' } },
  };
}

//***********EXPENSE CATEGORY****************
//add expense category api***************************************
export function addExpenseCategory(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/expcategory?code=${code}`;
  return {
    type: authObject.ADD_EXPCATEGORY,
    payload: { request: { url, data, method: 'POST' } },
  };
}

//view expense category api***************************************
export function viewExpenseCategory(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/expcategory?code=${code}`;
  return {
    type: authObject.VIEW_EXPCATEGORY,
    payload: { request: { url, data, method: 'GET' } },
  };
}

//delete expense category 
export function deleteExpenseCategory(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/expcategory/${id}?code=${code}`;
  return {
    type: authObject.DELETE_EXPCATEGORY,
    payload: { request: { url, method: 'DELETE' } },
  };
}

//get expense category by id
export function getExpenseCategory(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/expcategory/${id}?code=${code}`;
  return {
    type: authObject.GET_EXPCATEGORY,
    payload: { request: { url, method: 'GET' } },
  };
}

//edit expense catagory api**************************************
export function addEditedExpenseCategory(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/expcategory/${id}?code=${code}`;
  return {
    type: authObject.EDIT_EXPCATEGORY,
    payload: { request: { url, data, method: 'PUT' } },
  };
}

//***********EXPENSE****************
//add expense details api***************************************
export function addExpense(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/expense?code=${code}`;
  return {
    type: authObject.ADD_EXPENSE,
    payload: { request: { url, data, method: 'POST' } },
  };
}

//view all expense api*************************************
export function viewAllExpense(dateStart, dateEnd, catrogies, subCatrogies, paymentMode) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/expense?dateStart=${dateStart}&dateEnd=${dateEnd}&mainCategory=${catrogies}&subCategory=${subCatrogies}&paymentMode=${paymentMode}&code=${code}`;
  return {
    type: authObject.VIEW_EXPENSE,
    payload: { request: { url, method: 'GET' } },
  };
}

//delete expense details 
export function deleteExpense(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/expense/${id}?code=${code}`;
  return {
    type: authObject.DELETE_EXPENSE,
    payload: { request: { url, method: 'DELETE' } },
  };
}

//get expense details by id
export function getExpense(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/expense/${id}?code=${code}`;
  return {
    type: authObject.GET_EXPENSE,
    payload: { request: { url, method: 'GET' } },
  };
}

//edit expance api**************************************
export function addEditedExpense(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/expense/${id}?code=${code}`;
  return {
    type: authObject.EDIT_EXPENSE,
    payload: { request: { url, data, method: 'PUT' } },
  };
}

//get expense details by id
export function getSubCategory(sub) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/maincategory/${sub}?code=${code}`;
  return {
    type: authObject.GET_SUBCATEGORY,
    payload: { request: { url, method: 'GET' } },
  };
}

//***********DAYBOOK****************
//add daybook api***************************************
export function addDayBook(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/daybook?code=${code}`;
  return {
    type: authObject.ADD_DAYBOOK,
    payload: { request: { url, data, method: 'POST' } },
  };
}

//view daybook api*************************************
export function viewDayBook(startDate, endDate, particulars) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/daybook?startDate=${startDate}&endDate=${endDate}&particulars=${particulars}&code=${code}`;
  return {
    type: authObject.VIEW_DAYBOOK,
    payload: { request: { url, method: 'GET' } },
  };
}

//get daybook by id api*************************************
export function getDayBook(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/daybook/${id}?code=${code}`;
  return {
    type: authObject.GET_DAYBOOK,
    payload: { request: { url, method: 'GET' } },
  };
}

//edit daybook api*************************************
export function editDayBook(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/daybook/${id}?code=${code}`;
  return {
    type: authObject.EDIT_DAYBOOK,
    payload: { request: { url, data, method: 'PUT' } },
  };
}

//delete daybook by id api*************************************
export function deleteDayBook(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/daybook/${id}?code=${code}`;
  return {
    type: authObject.DELETE_DAYBOOK,
    payload: { request: { url, method: 'DELETE' } },
  };
}

//***********SETTINGS****************
//****COMPANY DETAILS */

//edit company api***************************************
export function addCompanyDetails(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/company?code=${code}`;
  return {
    type: authObject.ADD_COMPANY,
    payload: { request: { url, data, method: 'POST' } },
  };
}

//edit company api***************************************
export function editCompanyDetails(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/company/${id}?code=${code}`;
  return {
    type: authObject.EDIT_COMPANY,
    payload: { request: { url, data, method: 'PUT' } },
  };
}

//view all customer api*************************************
export function getCompanyDetails(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/company/${id}?code=${code}`;
  return {
    type: authObject.GET_COMPANY,
    payload: { request: { url, method: 'GET' } },
  };
}

//****GENERAL SETTINGS */
// add settings***************************************
export function addGeneralSettings(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/settings?code=${code}`;
  return {
    type: authObject.ADD_SETTINGS,
    payload: { request: { url, data, method: 'POST' } },
  };
}

// edit settings***************************************
export function editGeneralSettings(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/settings/${id}?code=${code}`;
  return {
    type: authObject.EDIT_SETTINGS,
    payload: { request: { url, data, method: 'PUT' } },
  };
}

//view general settings*************************************
export function getGeneralSettings(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/settings/${id}?code=${code}`;
  return {
    type: authObject.GET_SETTINGS,
    payload: { request: { url, method: 'GET' } },
  };
}

//****PRINT LABEL */
//view orders*************************************
export function viewOrders(next) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/printlabel?page=${next}&code=${code}`;
  return {
    type: authObject.VIEW_ORDERS,
    payload: { request: { url, method: 'GET' } },
  };
}

//get orders by id*************************************
export function getLabels(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/printlabel/${id}?code=${code}`;
  return {
    type: authObject.GET_LABEL,
    payload: { request: { url, method: 'GET' } },
  };
}


//VIEW PRIORITY LIST*************************************
//get all
export function viewPriorityList(startDate, endDate, delDate, customer, area, account, invoice,next, assgEmp, assgDriver, statuss,search) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/priority?startDate=${startDate}&endDate=${endDate}&deliveryDate=${delDate}&customer=${customer}&area=${area}&account=${account}&invoice=${invoice}&page=${next}&emp=${assgEmp}&driver=${assgDriver}&status=${statuss}&code=${code}&search=${search}`;
  return {
    type: authObject.VIEW_PRIORITY,
    payload: { request: { url, method: 'GET' } },
  };
}

//*************ORDER **************/

//add new  orders
export function addNewOrder(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/order?code=${code}`;
  return {
    type: authObject.ADD_ORDER,
    payload: { request: { url, data, method: 'POST' } },
  };
}

//view all orders*******************************
export function viewAllOrder() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/order?code=${code}`;
  return {
    type: authObject.VIEW_ORDER,
    payload: { request: { url, method: 'GET' } },
  };
}


//delete order by id api*************************************
export function deleteOrder(id,userName) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/order/${id}?code=${code}`;
  return {
    type: authObject.DELETE_ORDER,
    payload: { request: { url, 
      method: 'DELETE',
      data:{userName}
    } },
  };
}

//get order by id api*************************************
export function getOrder(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/order/${id}?code=${code}`;
  return {
    type: authObject.GET_ORDER,
    payload: { request: { url, method: 'GET' } },
  };
}

//edit order by id api*************************************
export function editOrder(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/order/${id}?code=${code}`;
  return {
    type: authObject.EDIT_ORDER,
    payload: { request: { url, data, method: 'PUT' } },
  };
}

//change order status
export function changeOrderStatus(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/status/${id}?code=${code}`;
  return {
    type: authObject.CHANGE_STATUS,
    payload: { request: { url, data, method: 'PUT' } },
  };
}

//*************DASHBOARD */
//view all orders ready for delivery
export function viewReadyOrder(page) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/dashboard?code=${code}&page=${page}`;
  return {
    type: authObject.VIEW_DASHBOARD,
    payload: { request: { url, method: 'GET' } },
  };
}

export function dashboardFilter(dateStart, dateEnd, invoice, customer, account, status) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/dashboardFilter?startDate=${dateStart}&endDate=${dateEnd}&invoice=${invoice}&customer=${customer}&account=${account}&status=${status}&code=${code}`;
  return {
    type: authObject.DASHBOARD_FILTER,
    payload: { request: { url, method: 'GET' } },
  };
}

//******POS*******************/
//view cloth details in pos
export function viewPos(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/pos/${data}?&code=${code}`;
  return {
    type: authObject.POS_VIEW,
    payload: { request: { url, method: 'GET' } },
  }
}

//payment pending orders view
export function viewPendingPayment(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/paymentView/${id}?code=${code}`;
  return {
    type: authObject.PAYMENT_VIEW,
    payload: { request: { url, method: 'GET' } },
  }
}

//add new cloth in pos
export function addClothPos(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/clothAddPos?code=${code}`;
  return {
    type: authObject.POS_ADDCLOTH,
    payload: { request: { url, data, method: 'POST' } },
  };
}

//employee assign view
export function employeeAssign() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/empPendingOrder?code=${code}`;
  return {
    type: authObject.ASSIGN_EMPLOYEE,
    payload: { request: { url, method: 'GET' } },
  }
}

//driver assign view
export function driverAssign() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/driverPendingOrder?code=${code}`;
  return {
    type: authObject.ASSIGN_DRIVER,
    payload: { request: { url, method: 'GET' } },
  }
}

//pick up driver assign
export function pickupDriverAssign() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/pickupDriverAssign?code=${code}`;
  return {
    type: authObject.PICKUP_DRIVER,
    payload: { request: { url, method: 'GET' } },
  }
}

//cleaning orders view
export function cleaningView(clPage) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/cleaningView?page=${clPage}&code=${code}`;
  return {
    type: authObject.CLEANING_VIEW,
    payload: { request: { url, method: 'GET' } },
  }
}

//compleated orders view
export function viewCompleteOrder(page) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/completedView?code=${code}&page=${page}`;
  return {
    type: authObject.COMPLETED_VIEW,
    payload: { request: { url, method: 'GET' } },
  }
}

//received orders view
export function receivedView(page) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/receivedView?code=${code}&page=${page}`;
  return {
    type: authObject.RECEIVED_VIEW,
    payload: { request: { url, method: 'GET' } },
  }
}

//received orders view
export function completedView() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/completedView?code=${code}`;
  return {
    type: authObject.COMPLETED_VIEW,
    payload: { request: { url, method: 'GET' } },
  }
}

//sorting
export function sort(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/sorting/${id}?code=${code}`;
  return {
    type: authObject.SORTING,
    payload: { request: { url, data, method: 'PUT' } }
  }
}

//add customer in pos and
export function addCustomerPos(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/addcustomerpos/${id}?code=${code}`;
  return {
    type: authObject.SHOW_CUSTOMER,
    payload: { request: { url, data, method: 'POST' } }
  }
}

//customer due amount
export function customerDueAmount(id,orderId) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/customerTotalBalance/${id}?orderid=${orderId}&code=${code}`;
  return {
    type: authObject.DUE_AMOUNT,
    payload: { request: { url, method: 'GET' } }
  }
}

//pending order in add more details
export function pendingOrder(date) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/pendingOrder/${date}?code=${code}`;
  return {
    type: authObject.PENDIG_ORDER,
    payload: { request: { url, method: 'GET' } }
  }
}

//payment
// export function payment(id, data) {
//   const code = localStorage.getItem("companyCode")
//   const url = `/api/syswash/payment/${id}?code=${code}`;
//   return {
//     type: authObject.PAYMENT_ORDER,
//     payload: { request: { data, url, method: 'PUT' } }
//   }
// }

// ADD Payment
export function payment(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/paymentOrder?code=${code}`;
  return {
    type: authObject.ADD_PAYMENT,
    payload: { request: { data, url, method: 'POST' } }
  }
}

//payment
export function paymentUpdate(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/payment/${id}?code=${code}`;
  return {
    type: authObject.PAYMENT_ORDER,
    payload: { request: { data, url, method: 'PUT' } }
  }
}
// Edit Payment
export function paymentEdit(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/paymentOrder/${id}?code=${code}`;
  return {
    type: authObject.EDIT_PAYMENT,
    payload: { request: { data, url, method: 'PUT' } }
  }
}

//closing report in pos
export function closingReport(dateStart, dateEnd,user) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/closingReport?startDate=${dateStart}&endDate=${dateEnd}&user=${user}&code=${code}`;
  return {
    type: authObject.CLOSING_REPORT,
    payload: { request: { url, method: 'GET' } }
  }
}

//Report**************************************************
//Order Report
export function orderReport(dateStart, dateEnd, delDate, invoice, customer, status, account, currentStatus, assgEmp, assgDriver, next, phoneNO,payment,search) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/orderReport?startDate=${dateStart}&endDate=${dateEnd}&deliveryDate=${delDate}&invoice=${invoice}&customer=${customer}&status=${status}&account=${account}&currentStatus=${currentStatus}&emp=${assgEmp}&driver=${assgDriver}&code=${code}&page=${next}&phonenumber=${phoneNO}&payment=${payment}&search=${search}`;
  return {
    type: authObject.ORDER_REPORT,
    payload: { request: { url, method: 'GET' } }
  }
}

//Order Report Excell
export function orderreportexcell(dateStart, dateEnd, delDate, invoice, customer, status, account, currentStatus, assgEmp, assgDriver, next, phoneNO,payment,search) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/orderreportexcell?startDate=${dateStart}&endDate=${dateEnd}&deliveryDate=${delDate}&invoice=${invoice}&customer=${customer}&status=${status}&account=${account}&currentStatus=${currentStatus}&emp=${assgEmp}&driver=${assgDriver}&code=${code}&page=${next}&phonenumber=${phoneNO}&payment=${payment}&search=${search}`;
  return {
    type: authObject.ORDER_REPORT_EXCEL,
    payload: { request: { url, method: 'GET',responseType: 'arraybuffer'  } }
  }
}

//sales report
export function salesReport(dateStart, dateEnd, invoice, customer, account) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/salesReport?startDate=${dateStart}&endDate=${dateEnd}&invoice=${invoice}&customer=${customer}&account=${account}&code=${code}`;
  return {
    type: authObject.SALES_REPORT,
    payload: { request: { url, method: 'GET' } }
  }
}

//cash ledger report
export function cashLedgerReport(dateStart, dateEnd, invoice, mode) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/cashLedgerReportNew?startDate=${dateStart}&endDate=${dateEnd}&invoice=${invoice}&mode=${mode}&code=${code}`;
  return {
    type: authObject.CASHLEDGER_REPORT,
    payload: { request: { url, method: 'GET' } }
  }
}


//debtors report
export function debtorsReport(dateStart, dateEnd, customer, account, area) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/debtorsReport?dateStart=${dateStart}&dateEnd=${dateEnd}&customer=${customer}&account=${account}&area=${area}&code=${code}`;
  return {
    type: authObject.DEBTORS_REPORT,
    payload: { request: { url, method: 'GET' } }
  }
}

//debtors report by order
export function debtorsReportByOrder(id, dateStart, dateEnd) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/debtorsOrderReport/${id}?startDate=${dateStart}&endDate=${dateEnd}&code=${code}`;
  return {
    type: authObject.DEBTORS_REPORT_ORDER,
    payload: { request: { url, method: 'GET' } }
  }
}

//Customer Report
export function customerReport(account, area,customer,next,billcount,search ) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/customerReport?accounts=${account}&area=${area}&customer=${customer}&page=${next}&billcount=${billcount}&code=${code}&search=${search} `;
  return {
    type: authObject.CUSTOMER_REPORT,
    payload: { request: { url, method: 'GET' } }
  }
}

//Customer Report
export function customerPos() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/poscustomer?code=${code}`;
  return {
    type: authObject.CUSTOMER_POS,
    payload: { request: { url, method: 'GET' } }
  }
}

export function customerReportByOrder(id, dateStart, dateEnd) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/customerReport/${id}?startDate=${dateStart}&endDate=${dateEnd}&code=${code}`;
  return {
    type: authObject.CUSTOMER_REPORT_ORDER,
    payload: { request: { url, method: 'GET' } }
  }
}

//Employee Report
export function employeeReport(customer, area) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/employeeReport?EmployeeName=${customer}&EmployeeAddress=${area}&code=${code}`;
  return {
    type: authObject.EMPLOYEE_REPORT,
    payload: { request: { url, method: 'GET' } }
  }
}

export function employeeReportByOrder(id, dateStart, dateEnd) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/employeeReport/${id}?startDate=${dateStart}&endDate=${dateEnd}&code=${code}`;
  return {
    type: authObject.EMPLOYEE_REPORT_ORDER,
    payload: { request: { url, method: 'GET' } }
  }
}

//driver report
export function driverReport(dateStart, dateEnd, customer, area) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/driverReport?dateStart=${dateStart}&dateEnd=${dateEnd}&DriverName=${customer}&address=${area}&code=${code}`;
  return {
    type: authObject.DRIVER_REPORT,
    payload: { request: { url, method: 'GET' } }
  }
}

export function driverReportByOrder(id, dateStart, dateEnd) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/driverReport/${id}?startDate=${dateStart}&endDate=${dateEnd}&code=${code}`;
  return {
    type: authObject.DRIVER_REPORT_ORDER,
    payload: { request: { url, method: 'GET' } }
  }
}

//expense report
export function expenseReport(dateStart, dateEnd, mainCat, subCat, payMode) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/expenseReport?startDate=${dateStart}&endDate=${dateEnd}&main=${mainCat}&sub=${subCat}&paymentMode=${payMode}&code=${code}`;
  return {
    type: authObject.EXPENSE_REPORT,
    payload: { request: { url, method: 'GET' } }
  }
}


//ready for delivery report
export function readyForDeliveryReport(dateStart, dateEnd, invoice,next, customer, delDate, account,search) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/readyForDeliveryReport?startDate=${dateStart}&endDate=${dateEnd}&invoice=${invoice}&page=${next}&customer=${customer}&deliveryDate=${delDate}&accounts=${account}&code=${code}&search=${search}`;
  return {
    type: authObject.READYFORDELIVERY_REPORT,
    payload: { request: { url, method: 'GET' } }
  }
}


//itemwise report
export function itemwiseReport(dateStart, dateEnd, item) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/itemWiseReport?startDate=${dateStart}&endDate=${dateEnd}&itemName=${item}&code=${code}`;
  return {
    type: authObject.ITEMWISE_REPORT,
    payload: { request: { url, method: 'GET' } }
  }
}

//transaction report
export function transactionReport(dateStart, dateEnd, mode, transaction) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/transactionReport?startDate=${dateStart}&endDate=${dateEnd}&mode=${mode}&transaction=${transaction}&code=${code}`;
  return {
    type: authObject.TRANSACTION_REPORT,
    payload: { request: { url, method: 'GET' } }
  }
}

//edit history report
export function editHistoryReport(dateStart, dateEnd) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/EdithistoryReport?startDate=${dateStart}&endDate=${dateEnd}&code=${code}`;
  return {
    type: authObject.EDIT_HISTORY_REPORT,
    payload: { request: { url, method: 'GET' } }
  }
}

//All Deptors report
export function debtorsAll(startDate, endDate, customer, account,next, area,search) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/debtorsAllOrderReport?startDate=${startDate}&endDate=${endDate}&customer=${customer}&account=${account}&page=${next}&area=${area}&code=${code}&search=${search}`;
  return {
    type: authObject.ALL_DEBTORS_REPORT,
    payload: { request: { url, method: 'GET' } }
  }
}

//multiple payment option
export function multiPayment(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/multiPayment?code=${code}`;
  return {
    type: authObject.MULTIPLE_PAYMENT,
    payload: { request: { url, data, method: 'PUT' } }
  }
}

//servicewise report
export function customerservicereport(dateStart, dateEnd,acType,customer) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/customerservicereport?startDate=${dateStart}&endDate=${dateEnd}&acType=${acType}&cusName=${customer}&code=${code}`;
  return {
    type: authObject.SERVICEWISE_REPORT,
    payload: { request: { url, method: 'GET' } }
  }
}

//***********OUTDOOR SERVICE****************
//add outdoor service api***************************************
export function addOutdoorService(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/outdoorService?code=${code}`;
  return {
    type: authObject.ADD_OUTDOOR_SERVICE,
    payload: { request: { url, data, method: 'POST' } },
  };
}

//view all outdoor service api***************************************
export function viewOutdoorService() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/outdoorService?code=${code}`;
  return {
    type: authObject.VIEW_OUTDOOR_SERVICE,
    payload: { request: { url, method: 'GET' } },
  };
}

//get by id outdoor service api***************************************
export function getOutdoorService(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/outdoorService/${id}?code=${code}`;
  return {
    type: authObject.GET_OUTDOOR_SERVICE,
    payload: { request: { url, method: 'GET' } },
  };
}

//delete outdoor service api***************************************
export function deleteOutdoorService(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/outdoorService/${id}?code=${code}`;
  return {
    type: authObject.DELETE_OUTDOOR_SERVICE,
    payload: { request: { url, method: 'DELETE' } },
  };
}

//edit outdoor service api***************************************
export function editOutdoorService(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/outdoorService/${id}?code=${code}`;
  return {
    type: authObject.EDIT_OUTDOOR_SERVICE,
    payload: { request: { url, data, method: 'PUT' } },
  };
}

//***********MANAGE OUTDOOR SERVICE****************
//add new outdoor order api***************************************
export function addOutdoorOrder(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/outdoorOrder?code=${code}`;
  return {
    type: authObject.ADD_OUTDOOR_ORDER,
    payload: { request: { url, data, method: 'POST' } },
  };
}

//view all outdoor order api***************************************
export function viewOutdoorOrder() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/outdoorOrder?code=${code}`;
  return {
    type: authObject.VIEW_OUTDOOR_ORDER,
    payload: { request: { url, method: 'GET' } },
  };
}

//filter all outdoor orders
export function outdoorOrderFilter(dateStart, dateEnd, invoice, service, customer, employee, status) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/outdoorOrderFilter?startDate=${dateStart}&endDate=${dateEnd}&invoice=${invoice}&service=${service}&customer=${customer}&employee=${employee}&status=${status}&code=${code}`;
  return {
    type: authObject.FILTER_OUTDOOR_ORDER,
    payload: { request: { url, method: 'GET' } },
  };
}

//delete outdoor order
export function deleteOutdoorOrder(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/outdoorOrder/${id}?code=${code}`;
  return {
    type: authObject.DELETE_OUTDOOR_ORDER,
    payload: { request: { url, method: 'DELETE' } },
  };
}

//get outdoor order
export function getOutdoorOrder(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/outdoorOrder/${id}?code=${code}`;
  return {
    type: authObject.GET_OUTDOOR_ORDER,
    payload: { request: { url, method: 'GET' } },
  };
}

//edit outdoor order
export function editOutdoorOrder(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/outdoorOrder/${id}?code=${code}`;
  return {
    type: authObject.EDIT_OUTDOOR_ORDER,
    payload: { request: { url, data, method: 'PUT' } },
  };
}

//clossing balance
export function ClsngBal() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/customerBalance?code=${code}`;
  return {
    type: authObject.CLOSSING_BALANCE,
    payload: { request: { url, method: 'GET' } }
  }
}

//clossing balance
export function paginate(dateStart, customer, statuss, account, searchField, next) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/paginationApi?orderDate=${dateStart}&customerName=${customer}&status=${statuss}&paymentMode=${account}&&search=${searchField}&page=${next}&code=${code}`;
  return {
    type: authObject.PAGINATE,
    payload: { request: { url, method: 'GET' } }
  }
}

//Pick UP LIsring
export function pickUpData() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/pickup?code=${code}`;
  return {
    type: authObject.PICKUP_DRIVER,
    payload: { request: { url, method: 'GET' } }
  }
}

//Pick UP By Id
export function pickUpById(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/pickup/${id}?code=${code}`;
  return {
    type: authObject.PICKUP_DRIVER,
    payload: { request: { url, method: 'GET' } }
  }
}


//Pick UP Add
export function AddPickUp(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/pickup?code=${code}`;
  return {
    type: authObject.ADD_PICKUP,
    payload: { request: { url, data, method: 'POST' } }
  }
}

//Pick UP delete
export function deletePickUp(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/pickup/${id}?code=${code}`;
  return {
    type: authObject.DELETE_PICKUP,
    payload: { request: { url, method: 'DELETE' } }
  }
}


//Pick UP EDIT
export function editPickup(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/pickup/${id}?code=${code}`;
  return {
    type: authObject.EDIT_PICKUP,
    payload: { request: { url, data, method: 'PUT' } }
  }
}

//order LIsring
export function appOrderList() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/pickuporder?code=${code}`;
  return {
    type: authObject.APP_ORDERLIST,
    payload: { request: { url, method: 'GET' } }
  }
}

//order Edit
export function appOrderEdit(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/pickuporder/${id}?code=${code}`;
  return {
    type: authObject.APP_ORDER_EDIT,
    payload: { request: { url, data, method: 'PUT' } }
  }
}

//order add sts
export function appOrderStatusChanger(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/pickuporderstatus/${id}?code=${code}`;
  return {
    type: authObject.APP_ORDER_STATUS,
    payload: { request: { url, method: 'PUT' } }
  }
}

// App Delivery status
export function changeAppDeliveryStatus(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/deliverystatus/${id}?code=${code}`;
  return {
    type: authObject.CHANGE_APP_DELIVERY_STATUS,
    payload: { request: { url, data, method: 'PUT' } },
  };
}

//App order delete
export function deleteAppOrder(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/pickuporder/${id}?code=${code}`;
  return {
    type: authObject.DELETE_APP_ORDER,
    payload: { request: { url, method: 'DELETE' } }
  }
}

//App order By Id
export function appOrderById(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/pickuporder/${id}?code=${code}`;
  return {
    type: authObject.APP_ORDERLIST,
    payload: { request: { url, method: 'GET' } }
  }
}

//assign  Diver
export function assignDriver(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/delivery?code=${code}`;
  return {
    type: authObject.ADD_DRIVER,
    payload: { request: { url, data, method: 'POST' } }
  }
}

//order LIsring
export function deliveryList() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/delivery?code=${code}`;
  return {
    type: authObject.APP_ORDERLIST,
    payload: { request: { url, method: 'GET' } }
  }
}

//App order delete
export function deleteDeliveryOrder(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/delivery/${id}?code=${code}`;
  return {
    type: authObject.DELETE_APP_ORDER,
    payload: { request: { url, method: 'DELETE' } }
  }
}

// add wallet amnt
export function addWallet(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/addWallet?code=${code}`;
  return {
    type: authObject.ADD_WALLET,
    payload: { request: { url, data, method: 'POST' } },
  };
}

// Get wallet 
export function viewWallet(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/addWallet?code=${code}`;
  return {
    type: authObject.GET_WALLET,
    payload: { request: { url, data, method: 'GET' } },
  };
}

// Get Wallet Customers
export function viewWalletAllCustomer() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/cusWallet?code=${code}`;
  return {
    type: authObject.VIEW_WALLET_CUSTOMER,
    payload: { request: { url, method: 'GET' } },
  };
}

// Delete wallet 
export function deleteWallet(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/addWallet/${id}?code=${code}`;
  return {
    type: authObject.DELETE_WALLET,
    payload: { request: { url, method: 'DELETE' } },
  };
}

// Get Customers Wallet Report
export function walletReport(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/customerWalletReport/${id}?code=${code}`;
  return {
    type: authObject.WALLET_REPPORT,
    payload: { request: { url, method: 'GET' } },
  };
}

// ADD items to plant
export function addToPlant(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/plantList?code=${code}`;
  return {
    type: authObject.ADD_TO_PLANT,
    payload: { request: { data, url, method: 'POST' } }
  }
}

// Vew Plant
export function ViewPlantList(dateStart,dateEnd,sortingType) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/plantList?StartDate=${dateStart}&EndDate=${dateEnd}&sortingtype=${sortingType}&code=${code}`;
  return {
    type: authObject.VIEW_PLANT_LIST,
    payload: { request: { url, method: 'GET' } }
  }
}

//Edit Plant 
export function EditPlantList(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/plantList/${id}?code=${code}`;
  return {
    type: authObject.EDIT_PLANT_LIST,
    payload: { request: { url, data, method: 'PUT' } },
  };
}

// Delete plant
export function deletePlantList(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/plantList/${id}?code=${code}`;
  return {
    type: authObject.DELETE_PLANT_LIST,
    payload: { request: { url, method: 'DELETE' } },
  };
}

//Plant wise report
export function plantWiseReport(dateStart, dateEnd, plantName) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/plantWiseReport?startDate=${dateStart}&endDate=${dateEnd}&plantName=${plantName}&code=${code}`;
  return {
    type: authObject.PLANT_WISE_REPORT,
    payload: { request: { url, method: 'GET' } }
  }
}

//change Plant status
export function changePlantStatus(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/plantStatus/${id}?code=${code}`;
  return {
    type: authObject.CHANGE_PLANT_STATUS,
    payload: { request: { url, data, method: 'PUT' } },
  };
}

//  Payment Gateway for Client
export function clientPaymentGateway() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/ClientPaymetGateway?code=${code}`;
  return {
    type: authObject.CLIENT_PAYMENT,
    payload: { request: { url, method: 'POST' } }
  }
}

// Payment Modal Popup
export function paymentModalPopup(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/popupCheck/${id}?code=${code}`;
  return {
    type: authObject.PAYMENT_POPUP,
    payload: { request: { url, method: 'GET' } },
  };
}

// Pay Later
export function payLater(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/paylater/${id}?code=${code}`;
  return {
    type: authObject.PAY_LATER,
    payload: { request: { url, method: 'GET' } }
  }
}

// Super Admin Payment
export function superAdminPayment(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/SuperadminPaymet/${id}?code=${code}`;
  return {
    type: authObject.SUPER_ADMIN_PAYMENT,
    payload: { request: { url, method: 'GET' } }
  }
}

// order edit emailing
export function orderEditMail(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/ordereditemail/${id}?code=${code}`;
  return {
    type: authObject.ORDER_EDIT_MAIL,
    payload: { request: { url, method: 'PUT' } },
  };
}

// order rdy SMS Notification
export function orderRdySMSNotification(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/sendtemplatemessage/${id}?code=${code}`;
  return {
    type: authObject.ORDER_SMS_NOTIFICATION,
    payload: { request: { url, method: 'POST' } },
  };
}

// Closing rpt mail
export function closingReportMail(dateStart, dateEnd) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/closingReportemail?startDate=${dateStart}&endDate=${dateEnd}&code=${code}`;
  return {
    type: authObject.CLOSING_REPORT_MAIL,
    payload: { request: { url, method: 'GET' } }
  }
}


// Support mail complaint-registration
export function supportMail(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/support?code=${code}`;
  return {
    type: authObject.SUPPORT_MAIL,
    payload: { request: { url, data, method: 'POST' } },
  };
}

// Support mail complaint-table Get
export function feedBackGet() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/support?code=${code}`;
  return {
    type: authObject.FEEDBACK_GET,
    payload: { request: { url, method: 'GET' } }
  }
}

// Return mail for support 
export function complaintResponse(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/supportReturnMail?code=${code}`;
  return {
    type: authObject.COMPLAINT_RESPONSE_MAIL,
    payload: { request: { url, data, method: 'POST' } }
  }
}

//***********RACK****************
//add rack api***************************************
export function addRack(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/rack?code=${code}`;
  return {
    type: authObject.ADD_RACK,
    payload: { request: { url, data, method: 'POST' } },
  };
}

//view all racks
export function viewRack() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/rack?code=${code}`;
  return {
    type: authObject.VIEW_RACK,
    payload: { request: { url, method: 'GET' } },
  };
}

//delete racks
export function deleteRack(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/rack/${id}?code=${code}`;
  return {
    type: authObject.DELETE_RACK,
    payload: { request: { url, method: 'DELETE' } },
  };
}

//get rack by id
export function getRack(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/rack/${id}?code=${code}`;
  return {
    type: authObject.GET_RACK,
    payload: { request: { url, method: 'GET' } },
  };
}

//edit rack api**************************************
export function addEditedRack(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/rack/${id}?code=${code}`;
  return {
    type: authObject.EDIT_RACK,
    payload: { request: { url, data, method: 'PUT' } },
  };
}


// RACK LIST........
// ADD items to RACK
export function addToRack(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/rackList?code=${code}`;
  return {
    type: authObject.ADD_TO_RACK,
    payload: { request: { data, url, method: 'POST' } }
  }
}

// View Rack
export function ViewRackList(rackname = null) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/rackList?rackname=${rackname}&code=${code}`;
  return {
    type: authObject.VIEW_RACK_LIST,
    payload: { request: { url, method: 'GET' } }
  }
}

//Edit Rack 
export function EditRackList(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/rackList/${id}?code=${code}`;
  return {
    type: authObject.EDIT_RACK_LIST,
    payload: { request: { url, data, method: 'PUT' } },
  };
}

// Delete Rack
export function deleteRackList(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/rackList/${id}?code=${code}`;
  return {
    type: authObject.DELETE_RACK_LIST,
    payload: { request: { url, method: 'DELETE' } },
  };
}

//change rack status
export function changeRackStatus(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/rackStatus/${id}?code=${code}`;
  return {
    type: authObject.CHANGE_RACK_STATUS,
    payload: { request: { url, data, method: 'PUT' } },
  };
}


// QR Code update
export function qrCodeUpdate(id,user) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/qrcodeupdate/${id}?user=${user}&code=${code}`;
  return {
    type: authObject.QR_CODE_UPDATE,
    payload: { request: { url, method: 'GET' } }
  }
}

// Confirm password when order edit
export function Confirmpassword(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/Confirmpassword?code=${code}`;
  return {
    type: authObject.CLIENT_PAYMENT,
    payload: { request: { url,data, method: 'POST' } }
  }
}

//Price list import
export function ModalPriceListExcel() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/modelpricelistexcell?code=${code}`;
  return {
    type: authObject.MODALPRICE_LIST,
    payload: { request: { url, method: 'GET',responseType: 'arraybuffer' } },
  };
}

//Upload price list
export function UploadExcel(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/uploadexcell?code=${code}`;
  return {
    type: authObject.UPLOADPRICE_LIST,
    payload: { request: { url, data, method: 'POST' } },
  };
}

//database list get
export function DatabaseList() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/databaseslist?code=${code}`;
  return {
    type: authObject.DATABASE_LIST,
    payload: { request: { url, method: 'GET'} },
  };
}

//send notification
export function SendNotification(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/sendnotification?code=${code}`;
  return {
    type: authObject.SEND_NOTIFICATION,
    payload: { request: { url, data, method: 'POST' } },
  };
}

//notification list get
export function NotificationListView() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/notificationlist?code=${code}`;
  return {
    type: authObject.NOTIFICATION_LIST,
    payload: { request: { url, method: 'GET'} },
  };
}

//notification remove
export function removeNotification(id, data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/notificationlist/${id}?code=${code}`;
  return {
    type: authObject.REMOVE_NOTIFICATION,
    payload: { request: { url, data, method: 'PUT' } }
  }
}

//PRICE LIST PRICE EDIT
export function updatePriceList(data) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/priceedit?code=${code}`;
  return {
    type: authObject.PRICE_EDIT,
    payload: { request: { url, data, method: 'POST' } },
  };
}

// User logged in 
export function UserLoggedIn() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/usersloggedIn?code=${code}`;
  return {
    type: authObject.USER_LOGGEDIN,
    payload: { request: { url, method: 'GET'} },
  };
}

//USER LOGGED OUT
export function UserLoggedOut(id) {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/userlogout/${id}?code=${code}`;
  return {
    type: authObject.USER_LOGGEDOUT,
    payload: { request: { url, method: 'PUT' } }
  }
}

//To get customer list
export function CustomerList() {
  const code = localStorage.getItem("companyCode")
  const url = `/api/syswash/customerlist?code=${code}`;
  return {
    type: authObject.VIEW_CUSTOMER_LIST,
    payload: { request: { url, method: 'GET' } },
  };
}